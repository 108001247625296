.about{
    padding: 10px;
    display: grid;
    grid-template-columns: 100%;
    gap: 6rem;
    background: var(--black);
    color: white;
}
.about-paragraph{
    opacity: 0;
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    transition: 0.5s ease-in;
    transform: translateY(50px);
}
.about-paragraph.active{
    opacity: 1;
    transform: translateY(0);
}
.about-paragraph p{
    font-size: 1rem;
    line-height: 25px;
}
.about-paragraph-c{
    transform: translateX(20%);
    z-index: 1;
    background: var(--black);
    border-radius: 10px;
}
.about-paragraph-c-title{
    font-weight: 800;
    font-size: 1.85rem;
}
.about-paragraph-c-title h1{
    font-weight: 800;
    font-size: 1.85rem;
}
.about-paragraph-i{
    width: 100%;
    transform: translateX(-20%);
    z-index: 0;
}
.about-paragraph-i img{
    width: 100%;
    border-radius: 10px;
    max-height: 400px;
    z-index: 0;
}
@media only screen and (max-width: 820px) {
    .about-paragraph{
        grid-template-columns: 100%;
        align-items: center;
        transition: 0.5s ease-in;
        transform: translateY(50px);
    }
    .about-paragraph-c{
        transform: translateX(0%);
    }
    .about-paragraph-i{
        transform: translateX(0%);
    }
}