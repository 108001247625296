.intro-container{
    height: 100%;
}
.intro-container-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    padding: 20px;
    height: calc(100% - 40px);
    width: calc(100% - 40px);
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    z-index: 1;
}
.intro-container-text h1{
    font-size: 60px;
    margin-top: -1px;
    font-weight: 500;
    color: white;
}
.intro-container-text a{
    font-size: 20px;
    background: white;
    color: black;
    padding: 15px;
    border-radius: 6px;
    border: none;
    width: 400px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
}
@media only screen and (max-width: 700px) {
    .intro-container-text{
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 20px;
        justify-content: flex-end;
        background: transparent;
        backdrop-filter: none;
    }
    .intro-container-text h1{
        font-size: 40px;
    }
    .intro-container-text a{
        font-size: 20px;
        padding: 15px;
        border: none;
        width: 70%;
        max-width: 300px;
        margin: 0px auto;
        margin-bottom: 10px;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
    }
}