.contacts{
    padding: 10px;
    background-position: center;
    background-size: contain;
    background-image: radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%));
    background-repeat: no-repeat;
}
.contacts-content-form-title{
    display: flex;
    align-items: center;
    gap: 5px;
}
.contacts-content-form-title h1{
    margin-top: -1px;
}
.contacts-content-form-title svg{
    width: 30px;
    position: relative;
    bottom: 10px;
}
.contacts-content-form{
    width: fit-content;
    display: flex;
    flex-direction: column;
    padding: 40px 60px;
    gap: 4px;
    margin: 0 auto;
    border-radius: 10px;
    background: white;
    box-shadow: hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px;
    border: 2px solid hsla(220, 30%, 5%, 0.05);
}
.contacts-content-form-item input,.contacts-content-form-item textarea{
    width: 100%;
    border: 2px solid hsla(220, 30%, 5%, 0.05);
    border-radius: 6px;
    padding: 5px;
    font-size: 17px;
    font-family: "Wix Madefor Text", sans-serif;
}
.contacts-content-form-item button{
    position: relative;
    left: 50%;
    transform: translate(-50%);
    background: var(--black);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px; 
    font-weight: 500;
    border-radius: 6px;
    width: 100%;
}
.contacts-content-img{
   width: 100%;
}
.contacts-content-img img{
    width: 100%;
    border-radius: 10px;
}