footer{
    background: var(--black);
    padding: 30px 10px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    flex-direction: row;
    justify-content: space-between;
    height: 150px;
}
footer small{
    color: white;
}
footer a{
    color: white;
    font-size: 14px;
}
footer button{
    background: white;
    color: var(--black);
    border: none;
    cursor: pointer;
    padding: 1px 10px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 5px;
}
footer svg{
    width: 20px;
    fill: white;
    cursor: pointer;
}