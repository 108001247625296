.pageError{
    color: var(--red);
}
.pageError-title{
    font-size: 100px;
    font-weight: 700;
    text-align: center;
}
.pageError-content{
    padding: 10px;
    text-align: center;
}
.pageError-content-text{
    font-size: 22px;
    font-weight: 500;
}
.pageError-content-buttons button{
    background: var(--red);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 10px;
}