.login{
    padding: 10px;
    /*background-image: url("../../sfondo.png");*/
    background-position: center;
    background-size: contain;
    background-image: radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%));
    background-repeat: no-repeat;
}
.login-content{
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-content-title{
    padding: 10px;
    width: calc(100% - 20px);
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.login-content-title h1{
    color: var(--black);
    text-align: center;
}
.login-content-title img{
    height: 40px;
    width: auto;
}
.login-content-form{
    padding: 40px 60px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 10px;
    box-shadow: hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px;
    border: 2px solid hsla(220, 30%, 5%, 0.05);
}
.login-content-form-item{
    display: flex;
    gap: 10px;
}
.login-content-form-item input,.login-content-form-item select{
    width: 100%;
    border: 2px solid hsla(220, 30%, 5%, 0.05);
    border-radius: 6px;
    padding: 5px;
    font-size: 17px;
    font-family: "Wix Madefor Text", sans-serif;
}
.login-content-form-item button{
    position: relative;
    left: 50%;
    transform: translate(-50%);
    background: var(--black);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px; 
    font-weight: 500;
    border-radius: 6px;
    width: 100%;
}
.login-content-form-small{
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    color: var(--secondo);
    text-align: center;
}
@media only screen and (max-width: 820px){
    .login-content{
        grid-template-columns: 100%;
    }
    .login-content-form{
        height: 500px;
    }
}