.utente{
    padding: 0 10px;
    color: var(--black);
    background-position: center;
    background-size: contain;
    background-image: radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%));
    background-repeat: no-repeat;
}
.utente-title h1{
    margin-top: -1px;
    font-size: 28px;
    text-align: center;
}
.utente a{
    color: var(--black);
    font-weight: 600;
}
.utente-content-anonymous, .utente-content{
    position: relative;
    background: white;
    padding: 10px;
    border-radius: 10px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    margin: 20px 0;
    min-height: 80vh;
    box-shadow: hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px;
    border: 2px solid hsla(220, 30%, 5%, 0.05);
}
.utente-content-info{
    display: grid;
    grid-template-columns: 100%;
    gap: 10px;
}
.utente-content-info-item{
    padding: 6px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(0, 0, 0, 0.1);
}
.utente-content-info-item big{
    font-size: 15px;
    font-weight: 700;
}
.utente-content-map{
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    height: 350px;
}
.utente-content-map-black{
    background: rgba(0,0,0,0.4);
    color: white;
    height: 350px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    width: calc(100% - 20px);
    text-align: center;
}
.utente-content-button{
    display: flex;
    justify-content: space-around;
}
.utente-content-button-item button{
    border: 2px solid transparent;
    background: white;
    color: var(--red);
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    transition: 0.3s ease-in-out;
}
.utente-content-button-item button:hover{
    border: 2px solid var(--red);
}
.utente-content-anonymous-grid{
    display: grid;
    grid-template-columns: 25% 75%;
}
.utente-content-anonymous-grid-sx{
    width: 100%;
}
.utente-content-anonymous-grid-sx svg{
    width: 50px;
    fill: #f3f3f3;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.utente-content-anonymous-grid-dx{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.utente-content-anonymous-grid-dx-item{
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
}
.utente-content-anonymous-grid-dx-item big{
    font-size: 16px;
}
.utente-content-anonymous-grid-dx-item svg{
    width: 20px;
}
.utente-content-anonymous-grid-dx-item:nth-child(2){
    fill: var(--green);
    color: var(--green);
}
.utente-content-anonymous-grid-dx-item:nth-child(3){
    fill: var(--red);
    color: var(--red);
}
.utente-content-anonymous-grid-dx-item:nth-child(4){
    fill: var(--secondo);
    color: var(--secondo);
}
.utente-content-anonymous-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 10px auto;
}
.utente-content-anonymous-bottom p{
    font-size: 15px;
}
.utente-content-anonymous-bottom a{
    width: 70%;
    text-align: center;
    text-decoration: none;
    background: var(--black);
    font-size: 19px;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}