.leaflet-container{
    width: 100%;
    height: 100%;
    z-index: 0;
}
.leaflet-top.leaflet-left{
    top: 15%;
}
.leaflet-routing-container.leaflet-bar.leaflet-control{
    display: none;
}
.leaflet-routing-container.leaflet-bar.leaflet-routing-collapsible.leaflet-control{
    display: none;
}
.leaflet-control-attribution{
    padding: 0px 10px !important;
    border-radius: 30px;
    margin: 0 0 10px 0 !important;
    color: white !important;
    background: rgb(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(5px);
}
.leaflet-control-attribution a{
    color: var(--black) !important;
}
.leaflet-control-attribution .svg-info{
    width: 15px;
    position: relative;
    transform: translateY(3px);
}
.leaflet-control-layers{
    border-radius: 50% !important;
    border: none !important;
    box-shadow: 0 2px 5px #888 !important;
}
.leaflet-control-layers-expanded{
    padding: 10px !important;
    border-radius: 15px !important;
}
.leaflet-marker-icon img{
    box-shadow: 0 2px 5px #888;
}
.start-marker-icon img, .end-marker-icon img{
    box-shadow: none;
    border-radius: 50%;
}
.leaflet-marker-icon span{
    font-weight: 600;
}
.marker-cluster-small{
    color: #585858;
    background-image: linear-gradient(0deg, white -100%, white 100%);
    box-shadow: 0 2px 5px #888;
}
.marker-cluster-small div{
    background-image: linear-gradient(0deg, white -100%, white 100%);
}
.marker-cluster-medium{
    background-image: linear-gradient(62deg, var(--black) 0%, var(--black) 100%);
    color: white;
    box-shadow: 0 2px 5px #888;
}
.marker-cluster-medium div{
    background-image: linear-gradient(62deg, var(--black) 0%, var(--black) 100%);
}
.marker-cluster-large{
    color: white;
    background-image: linear-gradient(62deg, var(--black) 0%, var(--black) 100%);
    box-shadow: 0 2px 5px #888;
}
.marker-cluster-large div{
    background-image: linear-gradient(62deg, var(--black) 0%, var(--black) 100%);
}
.start-content-map-hidePanel{
    position: absolute;
    z-index: 1000;
    top: calc(15% + 130px);
    left: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.start-content-map-hidePanel-button{
    background: white;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    cursor: pointer;
    box-shadow: 0 2px 5px #888
}
.start-content-map-hidePanel-button svg{
    width: 15px;
}
.start{
    touch-action: none;
}
.start-content-map{
    width: 100%;
    margin: 0 auto;
    z-index: 0;
}
.start-content-map-button{
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    width: max-content;
    padding: 6px 12px;
    border-radius: 10px;
    background: var(--black);
    color:white;
    font-size: 16px;
    font-weight: 600;
    cursor:pointer;
    box-shadow: 0 2px 5px #888;
}
.start-content-map-button svg{
    width: 20px;
    fill: white;
}
.start-content-map-button big{
    border:none;
    background: transparent;
    color:white;
    font-size: 16px;
    font-weight: 600;
}
.start-content-map-select{
    position: absolute;
    top: 15%;
    right: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.start-content-map-select small{
    font-weight: 700;
}
.start-content-map-select select{
    background: white;
    padding: 5px 8px;
    font-size: 13px;
    border-radius: 20px;
    box-shadow: 0 2px 5px #888;
    border: none;
    width: 50px;
}
.line{
    background: transparent;
    height: 25px;
}
.line-line{
    background:white;
    box-shadow: 0px 0px 10px #888;
    height:8px;
    width:50px;
    margin:5px auto;
    border-radius:10px;
    cursor:pointer;
}
.start-content-map-city{
    position: absolute;
    top: calc(15% + 90px);
    left: 10px;
    z-index: 1000;
    text-align: center;
    height: fit-content;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.start-content-map-city-svg{
    padding: 8px;
    background: white;
    border-radius: 50%;
    width: fit-content;
    box-shadow: 0 2px 5px #888;
    cursor: pointer;
    display: flex;
}
.start-content-map-city-svg svg{
    width: 15px;
}
.start-content-map-city-small small{
    font-weight: 700;
}
.start-content-map-types{
    position: absolute;
    top: 14px;
    left: calc(50% - 60px);
    transform: translateX(-100%);
    z-index: 1000;
    width: min-content;
    text-align: center;
    max-width: 100%;
    height: fit-content;
    background: transparent;
}
.start-content-map-types-filter-counter{
    position: absolute;
    display: flex;
    top: -7px;
    left: 20px;
    background: var(--black);
    padding: 1px 5px;
    border-radius: 50%;
    color: white;
    z-index: 1;
}
.start-content-map-types-filter{
    display: flex;
    position: relative;
    align-items: center;
    padding: 8px;
    margin-bottom: 10px;
    background: white;
    border-radius: 50%;
    width: fit-content;
    box-shadow: 0 2px 5px #888;
    cursor: pointer;
    left: calc(100% - 20px);
    transform: translateX(-100%);
}
.start-content-map-types-filter svg{
    width: 16px;
}
.start-content-map-types-scroll{
    position: relative;
    left: 90px;
    background: transparent;
    padding: 0px 2px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: transparent;
    overflow: hidden;
    overflow-Y: scroll;
    gap: 10px;
    height: min-content;
}
.start-content-map-types-scroll::-webkit-scrollbar{
    background: transparent;
    height: 8px;
}
.start-content-map-types-scroll::-webkit-scrollbar-thumb{
    background: #c2c2c2a4;
    border-radius: 20px;
}
.start-content-map-types-scroll-item{
    background-color: white;
    cursor: pointer;
    min-width: max-content;
    padding: 5px 8px;
    font-size: 15px;
    border-radius: 20px;
    box-shadow: 0 2px 5px #888;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    transition: 0.2s ease-in-out;
}
.start-content-map-types-scroll-item svg{
    width: 15px;
    fill: var(--black);
}
.start-content-map-links{
    position: absolute;
    top: calc(15% + 40px);
    right: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}
.start-content-map-links a{
    background: white;
    padding: 8px;
    border-radius: 20px;
    box-shadow: 0 2px 5px #888;
    color: var(--black);
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.start-content-map-links a svg{
    width: 15px;
    fill: var(--black);
}
.start-content-results{
    position: absolute;
    max-width: 96%;
    min-height: 200px;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    gap: 10px;
    z-index: 1;
    color: var(--black);
}
.start-content-results-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
}
.start-content-results-input input{
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    font-size: small;
    box-shadow: 0 2px 5px #888;
}
.start-content-results-input svg{
    width: 20px;
    fill: var(--red);
    cursor: pointer;
}
.start-content-results-scroll{
    padding: 5px 10px;
    text-align: center;
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    gap: 10px;
    transition: 0.2s ease-in;
    height: 200px;
}
.start-content-results-scroll::-webkit-scrollbar{
    display: none;
}
.start-content-results-item{
    border-radius: 5px;
    min-height: 100%;
    max-height: 100%;
    min-width: 250px;
    max-width: 250px;
    gap: 3px;
    background: var(--black);
    box-shadow: 0px 2px 5px #888;
}
.start-content-results-showMore{
    border-radius: 10px;
    width: max-content;
    padding: 10px;
    text-align: center;
    background: white;
    cursor: pointer;
    box-shadow: 0px 2px 5px #888;
}
.start-content-results-showMore svg{
    width: 20px;
}
.start-content-results-item-img{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    cursor: pointer;
}
.start-content-results-item-info{
    background-image: linear-gradient(315deg, #ffffff 0%, #f3f3f3 74%);
    width: 90%;
    bottom: 5px;
    left: 5%;
    padding: 15px 0px;
    position: relative;
    height: fit-content;
    transform: translateY(-110%);
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px #888;
    display: flex;
    flex-direction: row;
}
.start-content-results-item-info svg{
    width: 20px;
    margin: 0 auto;
    padding: 5px;
    fill: var(--secondo);
}
.start-content-results-item-svg{
    height: fit-content;
    padding: 2px;
    border-radius: 10px;
}
.start-content-results-item-svg svg{
    width: 30px;
    fill: var(--black);
    cursor: pointer;
}
.start-content-results-item-text{
    display: flex;
    flex-direction: column;
    gap: 1px;
    overflow: hidden;
    width: 100%;
    font-size: 12px;
}
.start-content-results-item-text .start-content-results-item-title{
    font-weight: 700;
}
.start-content-results-item-text small{
    color: var(--black);
}
.start-content-info{
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    z-index: 3;
    width: 100%;
    left: 0%;
    height: calc(95vh - 25px);
    transition: 0.2s ease-in;
    opacity: 0;
    transform: translateY(100%);
    background: transparent;
}
.start-content-info-content{
    height: 100%;
    overflow-y: scroll;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px -1px 10px #888;
}
.start-content-info-content::-webkit-scrollbar{
    display: none;
}
.start-content-info-content-commands{
    position: fixed;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
}
.start-content-info-content-commands-ai-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;
    background: white;
    border-radius: 20px;
    padding: 5px 10px;
    box-shadow: 0 2px 5px white;
}
.start-content-info-content-commands-ai-input svg{
    width: 20px;
    fill: var(--secondo);
}
.start-content-info-content-commands-ai-input input{
    border: none;
    padding: 5px 10px;
    font-size: medium;
    width: 100%;
}
.start-content-info-content-commands-ai-input button{
    background: transparent;
    cursor: pointer;
    border: none;
}
.start-content-info-content-commands-ai-input button svg{
    width: 15px;
    padding: 2px 0;
    fill: var(--black);
}
.start-content-info-content-commands-ai-response p{
    position: absolute;
    background: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 10px;
    box-shadow: 0 2px 5px #888;
    height: fit-content;
    max-height: 20vh;
    margin-top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    overflow: hidden;
    overflow-y: scroll;
    line-height: 25px;
}
.start-content-info-content-commands-ai-response p::-webkit-scrollbar{
    display: none;
}
.start-content-info-content-commands-item{
    padding: 9px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px #888;
    cursor: pointer;
}
.start-content-info-content-commands-item svg{
    width: 20px;
}
.start-content-info-content-content{
    padding: 0 2%;
    position: relative;
    top: 40%;
    background: rgba(58, 59, 60, 0.6);
    color: white;
    backdrop-filter: blur(10px);
    width: calc(100% - 4%);
    border-radius: 20px 20px 0 0;
}
.start-content-info-content-content-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.start-content-info-content-content-title-rating{
    display: flex;
    gap: 5px;
    align-items: center;
}
.start-content-info-content-content-title-rating svg{
    width: 20px;
    fill: #F7CE68;
}
.start-content-info-content-content-title-rating select{
    background: transparent;
    color: white;
    border: none;
}
.start-content-info-content-content-title-rating select option{
    background: rgba(58, 59, 60, 0.6);
    color: white;
    border: none;
}
.start-content-info-content-content::-webkit-scrollbar{
    display: none;
}
.start-content-info-content-content audio{
    width: 50%;
    min-width: 250px;
}
.start-content-info-content-content audio::-webkit-media-controls-panel{
    background-color: var(--primo);
}
.start-content-info-content-content audio::-webkit-media-controls-current-time-display{
    color: var(--black);
    font-weight: 600;
}
.start-content-info-content-content-img{
    overflow: hidden;
    overflow-x: scroll;
    display: flex;
    gap: 0px;
    width: 100%;
    height: 90px;
}
.start-content-info-content-content-img::-webkit-scrollbar{
    display: none;
}
.start-content-info-content-content-img a{
    padding: 5px;
}
.start-content-info-content-content-img a img{
    height: 80px;
    border-radius: 5px;
}
.start-content-info-content-grid{
    font-size: 13px;
    display: grid;
    grid-template-columns: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
}
.start-content-info-content-grid a{
    color: white;
    font-weight: 600;
}
.start-content-info-content big{
    font-weight: 600;
}
.start-content-info-content-summary{
    padding: 10px;
    border-radius: 10px;
    line-height: 25px;
}
.start-content-info-content-text{
    padding: 10px;
    border-radius: 10px;
    line-height: 25px;
}
.start-content-info-content-text::-webkit-scrollbar{
    display: none;
}
.start-content-info-content-list{
    padding: 10px;
    border-radius: 10px;
}
.start-content-info-content-list-item{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 5px;
}
.start-content-info-content-list-item-svg svg{
    width: 30px;
    fill: white;
}
.start-content-info-content-submitted{
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    overflow-x: scroll;
}
.start-content-info-content-submitted::-webkit-scrollbar,.start-content-info-content-summary::-webkit-scrollbar{
    display: none;
}
.start-content-info-content-submitted-item{
    background: var(--black);
    color: white;
    padding: 10px;
    border-radius: 20px;
}
.start-content-info-content-utenteActions{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 30px;
    background: var(--black);
    color: white;
}
.start-content-info-content-utenteActions-buttons{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.start-content-info-content-utenteActions-buttons-item{
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
}
.start-content-info-content-utenteActions-buttons-item svg{
    fill: white;
    width: 20px;
}
.start-content-info-content-utenteActions-form form{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}
.start-content-info-content-utenteActions-form form input,.start-content-info-content-utenteActions-form form textarea{
    width: 100%;
    border: none;
    padding: 5px;
    font-size: 17px;
    border-radius: 10px;
    font-family: "Wix Madefor Text", sans-serif;
}
.start-content-info-content-utenteActions-form form button{
    color: var(--black);
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 20px;
}
@media only screen and (max-width: 820px) {
    .start-content-results-control{
        flex-direction: column;
    }
}
@keyframes opacity {
    0% {
      opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}