body {
  margin: 0;
  font-family: "Wix Madefor Text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  touch-action: pan-y;
  color: var(--black);
}
select{
  font-family: "Wix Madefor Text", sans-serif;
}
:root{
  --black:#222;
  --secondo:#4a80f5;
  --red:#ff4b5a;
  --green:#50C878;
}
.lds{
  width: 100%;
  background: rgb(0, 0, 0,0.6);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 4;
}
.lds-c{
  z-index: 3;
  margin-top: 20%;
}
.lds-spinner{
  width: 200px;
  height: 50px;
  margin: 0 auto;
  background-image: url("./loading.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  animation: lds-spinner 3s ease-in-out infinite;
}
.fade-text-container {
  color: white;
  position: relative;
  height: 50px;
  overflow: hidden;
  text-align: center;
}

.fade-text {
  position: absolute;
  width: 100%;
  opacity: 0;
  animation: fadeInOut 12s ease-in-out infinite;
}

.fade-text:nth-child(1) {
  animation-delay: 0s;
}

.fade-text:nth-child(2) {
  animation-delay: 3s;
}

.fade-text:nth-child(3) {
  animation-delay: 6s;
}
.fade-text:nth-child(4) {
  animation-delay: 9s;
}
@keyframes lds-spinner {
  0%,100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
}
@keyframes fadeInOut {
  0%, 25%, 100% {
      opacity: 0;
  }
  10%, 20% {
      opacity: 1;
  }
}
.alert{
  position: fixed;
  z-index: 1000;
  top: 10px;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  color: white !important;
  box-shadow: 0 2px 5px #888 !important;
}
.alert svg{
  fill: white;
}
.dialog button{
  border-radius: 5px;
}
.dialog button:nth-child(1){
  color: black;
}
.dialog button:nth-child(2){
  color: var(--red);
}