.home-titles{
    overflow: hidden;
    background-image: url("https://images.pexels.com/photos/2422461/pexels-photo-2422461.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 35vh;
}
.home-titles-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 100%;
    transform: translateY(-100%);
    padding: 10px;
}
.home-titles-text{
    color: white;
}
.home-titles-text small{
    position: relative;
    top: 25px;
}
.home-titles-button a{
    text-decoration: none;
    color: black;
    background: white;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: 700;
}
.home-content{
    padding: 10px;
    background: white;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
}
.home-content-search{
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    z-index: 1;
}
.home-content-search-top{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.home-content-search-input{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border: 2px solid hsla(220, 30%, 5%, 0.05);
    border-radius: 20px;
    padding: 5px 10px;
    background: rgb(255, 255, 255,0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 5px #888;
}
.home-content-search-input svg{
    width: 20px;
    fill: var(--black);
}
.home-content-search-input input{
    width: 100%;
    border: none;
    padding: 5px;
    font-size: 15px;
    font-family: "Wix Madefor Text", sans-serif;
    background: transparent;
}
.home-content-search-top-ai{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid hsla(220, 30%, 5%, 0.05);
    background: rgb(255, 255, 255,0.9);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 10px #bbb;
    fill: var(--secondo);
}
.home-content-search-suggest{
    position: relative;
    background: white;
    box-shadow: 0 2px 10px #bbb;
    padding: 10px;
    width: 100%;
    border-radius: 20px;
    top: 10px;
    left: 50%;
    transform: translate(-50%);
}
.home-content-search-suggest big{
    font-size: 20px;
    font-weight: 700;
}
.home-content-search-suggest-content{
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    height: fit-content;
    max-height: 150px;
    overflow: hidden;
    overflow-y: scroll;
}
.home-content-search-suggest-content::-webkit-scrollbar{
    background: transparent;
    width: 5px;
}
.home-content-search-suggest-content::-webkit-scrollbar-thumb{
    background: var(--black);
    border-radius: 20px;
}
.home-content-search-suggest-content-item{
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 5px 0;
    border-top: 1px solid #ebebeb;
}
.home-content-search-suggest-content-item svg{
    width: 20px;
    fill: var(--black);
}
.home-content-avatar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.home-content-subtitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.home-content-subtitle h3{
    font-weight: 700;
}
.home-content-subtitle svg{
    width: 17px;
    background: var(--black);
    padding: 4px;
    fill: white;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease-in;
}
.home-content-subtitle small{
    font-weight: 900;
    font-size: 14px;
    cursor: pointer;
    color: var(--black);
    text-decoration: underline;
}
.home-content-popular{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    overflow: hidden;
    overflow-x: scroll;
    height: fit-content;
    min-height: 150px;
    padding: 10px;
    border-radius: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.home-content-popular::-webkit-scrollbar{
    display: none;
    background: transparent;
    height: 5px;
}
.home-content-popular::-webkit-scrollbar-thumb{
    background: var(--black);
    border-radius: 20px;
}
.home-content-popular a{
    text-decoration: none;
    color: var(--black);
}
.home-content-popular-arrow{
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    cursor: pointer;
    box-shadow: 0 2px 5px #888;
    border-radius: 50%;
    padding: 5px;
    fill: var(--black);
}
.home-content-popular-item{
    min-width: 250px;
    max-width: 250px;
    height: 180px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    overflow: hidden;
}
.home-content-popular-item.cronology{
    min-width: 150px;
    max-width: 150px;
    height: 100px;
}
.home-content-popular-item-continent{
    position: relative;
    left: 10px;
    top: 10px;
    padding: 2px 10px;
    background: var(--black);
    color: white;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    gap: 5px;
    z-index: 0;
}
.home-content-popular-item-continent svg{
    width: 15px;
    fill: white;
}
.home-content-popular-item-content{
    height: calc(100% - 20px);
    text-overflow: break-word;
    height: fit-content;
    padding: 10px;
}
.home-content-popular-item-content-big{
    margin-top: 5px;
    font-size: 17px;
    font-weight: 700;
}
.home-content-popular-item-content.cronology{
    min-width: 150px;
    max-width: 150px;
    word-break: break-all;
}
.home-content-popular-item-content-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home-content-popular-item-content-info big, .home-content-popular-item-content-info small{
    font-size: 14px;
    font-weight: 700;
}
.home-content-popular-item-content-info.cronology big, .home-content-popular-item-content-info.cronology small{
    font-size: 10px;
    font-weight: 700;
}
.home-content-popular-item-content-info svg{
    width: 14px;
    fill: #F7CE68;
}