nav{
    position: fixed;
    z-index: 2;
    padding: 2px 0;
    height: 50px;
    width: 100%;
    bottom: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 1px 10px;
    width: 100%;
    height: 100%;
    font-weight: 700;
    background: rgba(255, 255, 255);
    border-top: 1px solid #dddddd;
}
.navbar-menu img{
    width: 35px;
    border-radius: 50%;
    transition: 0.2s ease-in;
}
.navbar-item{
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    transition: 0.2s ease-in;
    fill: #a2ada5;
    color: #a2ada5;
    text-decoration: none;
    font-size: 14px;
}
.navbar-item svg, .navbar-item-me svg{
    width: 21px;
}
.navbar-item-me{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar-item-me big{
    position: absolute;
    font-size: 12px;
}
.navbar-list{
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: rgb(255, 255, 255,0.94);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    transition: 0.2s ease-in;
    font-size: 18px;
}
.navbar-list-exit{
    width: 1.5rem;
    padding: 10px;
    cursor: pointer;
}
.navbar-list-access{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2px 10px;
    transition: 0.2s ease-in;
}
.navbar-list-access a{
    border: 1px solid var(--black);
    padding: 8px 25px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: 500;
}
.navbar-list-item{
    width: 100%;
    padding: 2px 10px;
    transition: 0.2s ease-in;
}
.navbar-list-item a{
    color: var(--black);
    text-decoration: none;
    font-weight: 500;
    width: 100%;
}