.install{
    padding: 10px;
}
.install-content{
    gap: 10px;
}
.install-content-img img{
    width: 100%;
    border-radius: 6px;
}
.install-content-text-grid{
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 5%;
}
.install-content-text-grid-item{
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    background: var(--black);
    color: white;
    font-weight: 600;
}
.install-content-text-scroll{
    display: flex;
    gap: 10px;
    overflow-x: scroll;
}
.install-content-text-scroll-item{
    min-width: 200px;
    padding: 10px;
    border-radius: 6px;
    background: #f3f3f3;
    backdrop-filter: blur(10px);
}
.install-content-text-scroll::-webkit-scrollbar{
    display: none;
}
.install-content strong{
    color: var(--secondo);
}
@media only screen and (max-width: 600px) {
    .install-content{
        grid-template-columns: 100%;
    }
    .install-content-text-scroll-item{
        min-width: 80vw;
    }
}